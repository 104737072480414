import type { _BaseType } from './_base_type';
import type { PhotoURL } from './photo_url';
import BaseModel from './base';
import { ObjectId } from 'bson';

export type OutletType = _BaseType & {
  shopType: string
  code: string
  name: string
  contact?: string
  address1: string
  address2: string
  address3: string
  city: string
  postcode: string
  state: string
  country: string
  longitude: string
  latitude: string
  aboutUS: string

  stateCode: string

  channelId?: ObjectId
  // discount voucher
  defaultOfferId?: ObjectId

  managerId?: string
  agentId?: string

  photo?: string
  photoURL?: PhotoURL

  bannerPhoto?: string
  bannerPhotoURL?: PhotoURL

  orderCommPercent?: number
}

export class OutletModel extends BaseModel {
  constructor() {
    super('outlets');
    this.uniques = [['code']];
    this.required = ['name', 'code'];
  }
}
